/* .container-fluid {
  margin-top: 50px
} */


.bgLoaderContant {
  height: 80px;
  width: 80px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-image: url(.../images/bag.png); */
  background-image: url(../../public/images/bag.png);

  display: flex;
  justify-content: center;
  align-items: center;
}

.loaderContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  justify-content: center;
  display: flex;
  align-items: center;
  top: 0;
  background-color: #0a0a0a8c;
  z-index: 999;
  touch-action: none;
}

.planImg {
  margin: 10px 0 0 10px;
  width: 20px;
  animation: filp 4s linear infinite;
}

.ViewCard {
  text-align: center;
  border-radius: 20px;
  margin: auto;
  background-color: white;
}

.logoView {
  background: #ddd;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px;
}

.frm-Box {
  display: block;
  padding: 20px 40px;
}

@media (max-width:425px) {
  .frm-Box {
    padding: 20px 20px;
  }
}

input.txt-Box {
  height: 45px;
  border-radius: 25px;
  width: 100%;
  margin: 20px 0;
  /* padding: 0 15px; */
  font-size: 14px;
  display: block;
  font-family: "Poppins";
  border: 1px solid #e1e1e1;
}
input.txt-BoxField {
  display: block;
  font-family: "Poppins";
  border: 1px solid #e1e1e1;
  height: auto;
}

.extra {
  margin-block-end: 2px;
}

.queOptions {
  font-size: 14px;
  color: #7f7f7f;
  font-family: "Poppins";
  font-weight: 500;
  text-align: left;
  margin-right: 20px;
}

.terms-condition {
  display: flex;
  align-items: center;
}

@media screen and (max-width:500px) {
  .terms-condition {
    display: block;
    text-align: left;
  }

  .terms-condition p {
    padding-left: 0;
  }

  .queOptions {
    width: 100%;
  }

  .radio-input1 {
    float: left;
  }
}

.sign-btn {
  margin-top: 10px;
}

.termCondition {
  font-size: 14px;
  color: #000000;
  font-family: "Poppins";
  font-weight: 500;
  cursor: pointer;
  /* padding-left: 15px; */
}

.optionLabel {
  font-size: 14px;
  color: #000000;
  font-family: "Poppins";
  font-weight: 500;
}

.form-check-label {
  margin-bottom: -5px;
  margin-left: 3px;
  cursor: pointer;
}

.btn-theme {
  height: 50px;
  border-radius: 25px;
  background-color: #0b429a;
  font-size: 16px;
  display: inline-block;
  color: #ffffff;
  font-family: "Poppins";
  font-weight: 600;
}

.btn-theme:hover {
  color: #fff;
}

.p-10 {
  padding: 10px;
}

.ph-6 {
  padding: 6px 0;
}

.ph-15 {
  padding: 0 15px !important;
}

.ph-20 {
  padding: 0 20px !important;
}

.ph-30 {
  padding: 0 30px;
}

.ph-45 {
  padding: 0 45px;
}

.pl-0 {
  padding-left: 0px;
}

.pr-0 {
  padding-right: 0px;
}

.pvph {
  padding: 1px 8px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-15 {
  margin-left: 15px !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt_-15 {
  margin-top: 15px !important;
}

.mt_-40 {
  margin-top: -40px !important;
}

.mh-15 {
  margin: 0 15px;
}

.mhm-20 {
  margin: 0 -20px;
}

.mhm-30 {
  margin: 0 -30px;
}

.forgotPass {
  font-size: 14px;
  color: #0b429a;
  font-family: "Poppins";
  font-weight: 400;
  margin: 0 0 15px 0;
  cursor: pointer;
}

.linkTitle {
  font-size: 14px;
  color: #000000;
  font-family: "Poppins";
  font-weight: 500;
}

.linkLabel {
  font-size: 14px;
  color: #0b429a;
  font-family: "Poppins";
  font-weight: 500;
  cursor: pointer;
}

.box-cener {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: center;
  min-width: 0;
  -webkit-box-flex: 1;
  flex-grow: 1;
  min-height: calc(100vh);
}

.logoBox {
  width: 150px;
  height: 70px;
  border-radius: 5px;
  background-color: #ffffff;
}

.alignment {
  padding-inline-end: 3rem;
}

/* -------------- Header CSS -------------- */

.mainHeader {
  position: fixed;
  width: 100%;
  height: 80px;
  -webkit-box-shadow: 0 1px 15px rgba(0, 0, 0, .04), 0 1px 6px rgba(0, 0, 0, .04);
  box-shadow: 0 1px 15px rgba(0, 0, 0, .04), 0 1px 6px rgba(0, 0, 0, .04);
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: #09386e;
  z-index: 100;
}

.customerHeader {
  position: fixed;
  width: 100%;
  height: 80px;
  -webkit-box-shadow: 0 1px 15px rgba(0, 0, 0, .04), 0 1px 6px rgba(0, 0, 0, .04);
  box-shadow: 0 1px 15px rgba(0, 0, 0, .04), 0 1px 6px rgba(0, 0, 0, .04);
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  /* background: url('.../images/BgBlue.png') center no-repeat #09386e; */
  z-index: 100;
  background-color: #fff;
}

.Logo {
  height: 60px;
  width: 150px;
  background-color: white;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.LogoImg {
  /* height: 50px; */
  width: 120px;
}

.m-10 {
  margin: 10px;
}

.SearchBox {
  margin: auto 40px;
  border-radius: 5px;
  background: white;
  flex-wrap: wrap;
  flex-direction: row;
  display: flex;
  position: relative;
}

.searchIcon {
  padding: 5px 14px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.profilePicImg {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: #ffffff;
  float: right;
  margin-right: 10px;
  cursor: pointer;
}

.searchInput {
  font-size: 14px;
  font-family: "Poppins";
}

.searchInput:focus {
  outline: none;
  box-shadow: none;
}

.main-admin-wrap {
  /* width: 100%; */
  width: calc(100% - 120px);
  /* float: left; */
  float: right;
  margin-top: 80px;
  /* left: 120px; */
  -webkit-transition: all width .24s ease-in-out;
  transition: width .24s ease-in-out;
  padding: 2rem 2rem 0;
  position: relative;
  min-height: calc(100vh - 80px);
  background: #ededed;
  overflow: auto;
}

.select-country>div {
  border-radius: 50px;
  height: 45px;
  background-color: #f9f9f9;
  border: 1px solid #e1e1e1;
  font-size: 14px;
}

.main-customer-wrap {
  width: 100%;
  float: right;
  /* margin-top: 40px; */
  -webkit-transition: all width .24s ease-in-out;
  transition: width .24s ease-in-out;
  /* padding: 2rem 2rem 0; */
  position: relative;
  /* min-height: calc(100vh - 80px); */
  background: #fff;
  overflow: auto;
  /* min-height: 100vh; */
}

.side-position {
  position: absolute;
  z-index: 999;
  left: 0;
  right: 0;
  bottom: -30px;
}

.white-box {
  background: #fff;
}

@media (max-width: 992px) {
  .SearchBox {
    display: none;
  }
}

.service-radio {
  margin-top: 4px !important;
  cursor: pointer;
}

@media (max-width: 575px) {
  .sms-logo {
    width: 25%;
  }

  .sms-menu {
    width: 75%;
  }
}

@media (max-width: 440px) {
  .sms-logo {
    width: 100%;
  }

  .sms-menu {
    width: 100%;
  }

  .sms-menu a {
    padding-top: 0 !important;
    padding-left: 0px !important;
  }

  .sms-btn {
    justify-content: start !important;
  }

  .main-logo {
    justify-content: start;
    padding-left: 15px !important;
  }
}

/* @media (max-width:375px){
  .sms-menu{
    padding: 0;
  }
} */
/* ----------- SideBar ---------- */

/* .side-content-wrap {
  display: none !important;
  transition:left .24s ease-in-out !important;
} */

.side-content-wrap .sidebar-left {
  /* position: absolute; */
  position: fixed;
  top: 80px;
  height: calc(100vh - 80px);
  background: #fff;
  -webkit-box-shadow: 0 4px 20px 1px rgba(0, 0, 0, .06), 0 1px 4px rgba(0, 0, 0, .08);
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, .06), 0 1px 4px rgba(0, 0, 0, .08);
}

.sidebar-left {
  z-index: 90;
  -webkit-transition: left .24s ease-in-out;
  transition: left .24s ease-in-out;
}

.navigation-left {
  list-style: none;
  text-align: center;
  width: 120px;
  height: 100%;
  margin: 0;
  padding: 0;
  background:#09386e;
  /* overflow: auto; */
}

.nav-item {
  position: relative;
  display: block;
  width: 100%;
  height: 100px;
  color: #9ba9bb;
  cursor: pointer;
  border-bottom: 1px solid #9ba9bb;
}

.nav-item-hold {
  display: block;
  width: 100%;
  padding: 20px 0;
  color: #9ba9bb;
  z-index: 1;
  transition: font-size .24s ease-in-out !important
}

.nav-icon {
  height: 30px;
  width: 30px;
  display: block;
  margin: 0 auto;
  justify-content: center;
  z-index: 1;
}

.nav-text {
  font-size: 14px;
  color: #9ba9bb;
  display: block;
  margin-top: 10px;
  font-weight: 500 !important;
  font-family: "Poppins";
  z-index: 1;
}

a.nav-item-hold:hover {
  text-decoration: none !important;
}

li.nav-item:hover span {
  color: white;
  font-size: 16px;
  text-decoration: none !important;
  transition: font-size .24s ease-in-out !important
}

.sidebar-left .navigation-left .triangle {
  position: absolute;
  width: 0;
  height: 0;
  right: 0;
  bottom: 0;
  border-style: solid;
  border-width: 0 0 30px 30px;
  border-color: transparent transparent #33c5b1 transparent;
}

.ps {
  touch-action: auto;
  /* overflow: hidden !important; */
  overflow: inherit !important;
}

.ps-container {
  position: relative;
}

.sidebar-left .open {
  left: 0;
}

.border-Orange {
  border-color: #f39229 !important;
}

.bg-Green {
  background-color: #64b769 !important;
}

.border-Green {
  border-color: #64b769 !important;
}

.bg-Red {
  background-color: #e34e4a !important;
}

.border-Red {
  border-color: #e34e4a !important;
}

.bg-SkyBlue {
  background-color: #2fc1d0 !important;
}

.bg-e3e3e3 {
  background-color: #e3e3e3 !important;
}

.border-SkyBlue {
  border-color: #2fc1d0 !important;
}

.text-pending {
  color: #ff3b35;
}

.text-ship, .text-shipped {
  color: #f39229;
}

.text-received {
  color: #64b769;
}

.text-packed {
  color: #6b2504;
}

.text-onHold {
  color: #c31b4b;
}

.text-customerHold {
  color: #9fa950;
}

.text-refused {
  color: #5bc0de;
}

.text-missed {
  color: #337ab7;
}

.text-PReceived {
  color: #006400;
}

.bg-searchBlue {
  background-color: #0b429a !important;
}

.bg-resetRed {
  background-color: #e34e4a !important;
}

.bg-Orange {
  background-color: #f39229;
}

.bg-skyBlue {
  background-color: #2fc1d0;
}

.bg-blue {
  background-color: #598cdd;
}

.bg-Peach {
  background-color: gray;
  /* background-color: #ff9f9c; */
}

.bg-sender {
  background-color: #7eaefb;
}

.bg-lightBlue {
  background-color: #5890e6;
}

.btn-Groups {
  display: flex;
  justify-content: space-around;
  /* justify-content: space-evenly; */
}

.bg-lightGreen {
  background-color: #64b669;
}

.bg-lightBlueShade { 
  background-color: #6b9bfe;
}

.bg-Gray {
  background-color: #a9a9a9;
}

.bg-border-err {
  border: 1px solid red !important;
}

.input-focus:focus {
  border-color: red;
  box-shadow: none;
  outline: none;
}

.btn-text {
  font-size: 16px;
  color: #ffffff;
  font-family: "Poppins";
  font-weight: 500;
  padding: 6px 25px;
}

.statusStyle {
  padding: 2px 8px;
  font-family: Poppins;
  border-radius: 5px;
  justify-content: center;
  border: 1px solid;
}

.activeCustomer {
  border-color: #64b769 !important;
  color: #64b769 !important;
}

.inActiveCustomer {
  border-color: #e34e4a !important;
  color: #e34e4a !important;
}

.bootstrap-tagsinput input[type="text"] {
  width: auto;
  padding: 0px;
  display: none;
  border: 0px;
  display: -webkit-inline-box;
  margin: 0 0 12px 5px;
}

.bootstrap-tagsinput .tag {
  display: inherit;
  border: none;
  padding: 5px 10px;
  font-size: 14px;
}

.mainCount:hover p {
  content: '';
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: .25rem
}

.pagination li {
  position: relative;
  display: inline-block;
  height: 28px;
  min-width: 28px;
  padding: 0 2px;
  text-align: center;
  margin: 3px 5px;
  color: #0b429a !important;
  background-color: #eef4ff;
  border: 1px solid #0b429a;
  border-radius: 3px;
  line-height: 1.8;
}

.pagination li a {
  color: #0b429a;
}

.pagination li.previous.disabled a,
.pagination li.next.disabled a,
.pagination li.previous.disabled,
.pagination li.next.disabled {
  width: 65px;
  font-family: "Poppins";
  color: #6c757d;
  cursor: not-allowed !important;
  background-color: #fff;
  border-color: #dee2e6;
  box-shadow: none;
  line-height: 1.9;
  margin: 4px 1px 0 1px;
}

.pagination li.previous,
.pagination li.next,
.pagination li.previous a,
.pagination li.next a,
.pagination li.previous:hover,
.pagination li.next:hover {
  font-size: 16px !important;
  font-family: "Poppins" !important;
  font-weight: 900 !important;
  color: #0b429a !important;
  cursor: pointer !important;
  background-color: #fff !important;
  border: none;
  box-shadow: none;
  line-height: 1.9;
  margin: 4px 1px 0 1px;
  width: 65px;
}

.pagination li.active,
.pagination li:focus,
.pagination li:hover,
.pagination li.active a,
.pagination li:focus a,
.pagination li:hover a {
  z-index: 2;
  color: white;
  text-decoration: none;
  background-color: #0b429a;
  border-color: #337ab7;
  cursor: pointer;
  line-height: 1.8;
}

.pagination li.active a,
.pagination li:focus a,
.pagination li:hover a {
  z-index: 2;
  border: none;
  text-decoration: none !important;
  outline: 0;
  box-shadow: none;
}

.pagination-link {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

a:hover {
  text-decoration: none
}

/* ---------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

/* *, *::before, *::after {
  box-sizing: border-box;
}

body {
  font-family: "Sancreek", cursive;
}

.container {
  position: relative;
  height: 100vh;
  overflow: hidden;
  background: #282820;
  z-index: -1;
} */

.message {
  text-align: center;
  position: absolute;
  z-index: 1;
  width: 445px;
  height: 360px;
  margin: 0 auto;
  border: 20px solid #9ba9bb;
  background: #9ba9bb;
  border-radius: 20px;
  box-shadow: 0px 0px 0px 4px #471f05;
  animation-delay: 1s;
  animation-duration: 1.3s;
}

.containermain {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .message::before, .message::after {
  content: "";
  position: absolute;
  bottom: 107%;
  width: 50px;
  height: 341px;
  background: url(https://lh3.googleusercontent.com/2dRAAXiDgbbyDh25k-RskE28cyFs48q-VdmMIin5LCyGjS5HETfUq2bmH0xU9EwJioh5gw=s41) repeat-y center;
  z-index: -10;
} */

.message::before {
  left: 20px;
}

.message::after {
  right: 20px;
}

.message-inner {
  padding: 40px;
  border-radius: 20px;
  position: absolute;
  top: 2%;
  right: 2%;
  bottom: 2%;
  left: 2%;
  color: white;
  background: #062956;
}

.message-title {
  font-size: 4em;
  font-family: 'Poppins';
  margin: 0 0 20px;
}

.message-subtitle {
  font-size: 2em;
  font-family: 'Poppins';
  margin: 0;
}

.chain {
  position: absolute;
  top: 0;
  height: 200%;
  width: 50px;
  background: url(https://lh3.googleusercontent.com/2dRAAXiDgbbyDh25k-RskE28cyFs48q-VdmMIin5LCyGjS5HETfUq2bmH0xU9EwJioh5gw=s41) repeat-y center;
  animation: chain 1.8s ease-in-out;
}

.cog.left {
  left: -50px;
  animation: cog-spin-left 1.8s ease-in-out;
}

.cog.right {
  right: -50px;
  animation: cog-spin-right 1.8s ease-in-out;
}

.cog-outer {
  fill: #955112;
}

.cog-inner {
  fill: #633d03;
}

.rivet {
  position: absolute;
  background-color: #062956;
  width: 3%;
  border-radius: 100px;
  padding-bottom: 3%;
}

.rivet.top-left {
  top: -7px;
  left: -7px;
}

.rivet.top-right {
  top: -7px;
  right: -7px;
}

.rivet.bottom-left {
  bottom: -7px;
  left: -7px;
}

.rivet.bottom-right {
  bottom: -7px;
  right: -7px;
}

.thumbsContainer {
  height: 300px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.thumb {
  display: inline-flex;
  margin-bottom: 8;
  margin-right: 8;
  padding: 4;
  box-sizing: border-box
}

.thumbInner {
  display: flex;
  min-width: 0;
  overflow: hidden;
  height: 300px;
}

.thumbImg {
  display: block;
  width: 100%
}

.order-section {
  margin: 150px auto 0;
}

.order-sucess {
  text-align: center;
  max-width: 370px;
  width: 100%;
  margin: 0 auto;
  padding: 30px;
  background: #fff;
  border-top: 5px solid red;
  border-radius: 2px;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, .06), 0 1px 4px rgba(0, 0, 0, .08);
}

.order-data {
  padding: 0px 0 67px;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, .06), 0 1px 4px rgba(0, 0, 0, .08);
}

.order-data img {
  max-width: 100%;
}

.view-order {
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  padding: 9px 28px;
  display: inline-block;
  text-decoration: none;
  border-radius: 3px;
  background-color: #0b439a;
}

.order-place {
  font-size: 24px;
  color: #39890e;
  margin: 0;
  padding: 20px 0 70px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

.text-over {
  white-space: nowrap;
  width: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.descriptionToolTip {
  overflow: hidden;
  padding: 10px 3px;
}

.m-header {
  justify-content: unset;
  align-items: center;
  border: 0;
}

.m-footer {
  border: 0;
}

.open-menu {
  position: absolute;
  top: 104%;
  width: auto;
  left: 0;
  overflow-y: scroll;
  /* height: 300px; */
  max-height: 300px;
  -webkit-box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 5px;
}

.menu-data {
  padding: 2px 15px;
  font-size: 15px;
  color: #333333db;
  font-weight: normal;
}

.menu-data b {
  color: #000;
  font-weight: 900;
}

.menu-data:hover {
  color: #ffffff;
  background-color: #337ab7;
}

.shift-right {
  margin-right: 22px;
  float: right;
}

.pag-right {
  position: absolute;
  right: 0px;
  top: 7px;
}

.customer-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.order-menu {
  display: flex;
  align-items: center;
  background: #0B429A;
  padding: 15px 13px;
  width: 200px;
  border-radius: 5px;
  position: relative;
  margin: 0 10px;
  justify-content: center;
}

.icon-heading {
  color: #fff;
}

.icon-blue {
  color: #0B429A;
}

.white-box {
  background: #fff;
  -webkit-box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.5);
}

.triangle-down {
  width: 0;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 999;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 20px solid #0B429A;
}
.triangle-down-order{
  width: 0;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 999;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 20px solid #0B429A;
}
.icon-contain {
  padding-left: 0;
}

.panel {
  margin-top: -10px !important;
}

.panel-green {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid #5cb85c;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
}

.panel-green>.panel-heading {
  border-color: #5cb85c;
  color: white;
  background-color: #5cb85c;
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel-body {
  padding: 15px;
}

.page-header {
  font-size: 36px;
  margin: 15px 0;
}

.p-b-20 {
  padding-bottom: 20px;
}

.no-padding {
  padding-left: 0px;
  padding-right: 0px;
}

.table-responsive {
  padding-top: 0px !important;
}

.table {
  margin-top: 10px;
}

#totalreceived tr th,
#totalreceived tr td,
#totalreceived table {
  font-family: unset !important;
}

.drop-with-dot {
  position: relative;
  top: 10px;
  right: 10px;
}

@keyframes cog-spin-left {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes cog-spin-right {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}

@keyframes chain {
  from {
    top: 0;
  }

  to {
    top: -100%;
  }
}

.round-img {
  height: 40px;
  width: 40px;
  border-radius: 20px;
}

.round-img-big {
  height: 70px;
  width: 70px;
  border-radius: 35px;
}

.txtCenter {
  text-align: center;
}

/* remove arrows from input type number */
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

table.themeTable th.pr20,
table.themeTable td.pr20 {
  padding-right: 20px !important;
}

