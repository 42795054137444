.mainTitle {
  font-size: 40px;
  color: #ff5353;
  font-family: "Poppins";
  font-weight: 600;
}

.SubTitle {
  font-size: 16px;
  color: #000000;
  font-family: "Poppins";
  font-weight: 500;
}

.borderBottomOfTitle {
  border-bottom: 3px solid #bebebe;
  width: 70px;
  float: left;
}

.countBox {
  background: white;
  margin: 0px 15px;
  border: 2px solid;
  border-radius: 5px;
  box-shadow: none;
  transition: box-shadow ease-in-out 0.6s, transform ease-in-out 0.6s;
}

.barcode {
  transition: opacity 5s ease-in-out;
  margin: 2px 0px;
  color: #03409b;
}

.barcodeBox {
  justify-content: center;
  display: flex;
}

.countMainBox {
  height: 130px;
  border-radius: 5px;
  background-color: #ffffff;
  border: 2px solid;
  margin: 10px;
}

.IconBox {
  height: 100px;
  width: 100px;
  bottom: 30px !important;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.filpCard {
  transition: transform ease-in-out 0.6s;
  transform: rotateY(0deg);
}

.countBox:hover {
  box-shadow: 0 0 15px #aaa;
  transform: scale(0.9);
}

.countBox:hover .filpCard {
  transition: transform ease-in-out 0.6s;
  transform: rotateY(360deg);
}

.mainCount {
  margin: 15px 0px;
}

.counts {
  font-size: 20px;
  color: #7f7f7f;
  font-family: "Poppins";
  font-weight: 600;
  margin-bottom: 0px !important;
  float: right;
}

.countTitle {
  font-size: 20px;
  color: #000000;
  font-family: "Poppins";
  font-weight: 600;
  margin-bottom: 0px !important;
  float: right;
}

.viewDetail {
  font-size: 14px;
  color: #7f7f7f;
  font-family: "Poppins";
  font-weight: 400;
  border-top: 1px solid #e5e5e5;
  margin: 10px 0px;
  line-height: 3;
}



.countIconSize {
  height: 50px;
  width: 50px;
}

div.ibox {
  clear: both;
  margin-bottom: 30px;
  margin-top: 0;
  padding: 0;
  background-color: white;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.1), 0 0px 5px rgba(0, 0, 0, 0.01) !important;
  border-radius: 8px;
}

.ibox-title {
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px;
  color: inherit;
  margin-bottom: 0;
  padding: 10px 20px;
  min-height: 48px;
  position: relative;
  clear: both;
}

.ibox-content {
  color: inherit;
  padding: 15px 20px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid;
  border-width: 1px;
}

.iboxTitleSpan,
.ibox-title span {
  font-size: 18px;
  color: #547ab8;
  font-family: "Poppins";
  font-weight: 500;
}

table.themeTable th,
table.themeTable td {
  padding: 0.5rem !important;
  font-family: "Poppins";
}

table.themeTable th {
  font-size: 14px;
  color: #000000;
  font-family: "Poppins";
  font-weight: bold;
}

table.themeTable td {
  font-size: 14px;
  color: #8a8a8a;
  font-family: "Poppins";
  font-weight: 400;
}

table.themeTable td,
table.themeTable th {
  vertical-align: middle !important;
  white-space: nowrap;
}

table.table-faq td {
  white-space: inherit;
}

table.themeTable tbody tr:hover {
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2), 0 0px 10px rgba(0, 0, 0, 0.02) !important;
}

.width10 {
  width: 10px;
}

.width15 {
  width: 15px;
}

.width18 {
  width: 18px !important;
}

.width27 {
  width: 30px;
}

.width30 {
  width: 27px;
}

.width55 {
  width: 55px;
}

.width70 {
  width: 70px;
  height: 70px;
}

.width200 {
  width: 200px;
}

.width85 {
  width: 85%;
}

.statusLabel {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 600;
  margin-left: 5px;
  line-height: 2;
}

.btn-View {
  border-radius: 13px;
  background-color: #ffffff;
  border: 1px solid #0b429a;
  font-size: 12px;
  color: #0b429a;
  font-family: "Poppins";
  font-weight: 500;
  padding: 3px 8px;
  cursor: pointer;
}

.btn-View:hover {
  color: white;
  background-color: #0b429a;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.containerHeight535 {
  height: 535px;
}

.totalResetBoxes {
  height: 45px;
  border-radius: 5px;
  background-color: #ffffff;
  border: 2px solid #64b769;
  cursor: pointer;
}

.totalResetBoxesIcon {
  width: 15%;
  height: 42px;
  background-color: #64b769;
  justify-content: center;
  align-items: center;
  display: flex;
}

.borderColorRed {
  border-color: #e34e4a;
}

.boxBackColorRed {
  background-color: #e34e4a;
}

.totalBoxesText {
  font-size: 18px;
  color: #000000;
  font-family: "Poppins";
  font-weight: 500;
}

.BoxImg {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ffPoppins {
  font-family: "Poppins";
}

.frm-label {
  font-size: 14px;
  color: #000000;
  font-family: "Poppins";
  font-weight: bold;
}

.btn-outline-dark {
  transition: transform ease-in-out 0.6s;
}

.btn-outline-dark.active,
.btn-outline-dark:hover {
  transform: scale(0.9);
  color: #fff !important;
  background-color: #343a40 !important;
  border-color: #343a40 !important;
}

.btn-outline-Pending {
  color: #ff3b35;
  background-color: #fed3d1;
  background-image: none;
  border-color: #ff3b35;
  transition: transform ease-in-out 0.6s;
}

.btn-outline-Pending img {
  /* content: url('../../public/images/pending.png'); */
  content: url('../../public/images/pending.png');
  width: 18px !important;
  height: 18px !important;
}

.btn-outline-Pending.active img,
.btn-outline-Pending:hover img {
  content: url('../../public/images/activePending.png');
}

.btn-outline-Pending.active,
.btn-outline-Pending:hover {
  color: #fff;
  background-color: #ff3b35;
  border-color: #ff3b35;
  transform: scale(0.9);
}

.btn-outline-Received {
  color: #64b769;
  background-color: #dcf7de;
  background-image: none;
  border-color: #64b769;
  transition: transform ease-in-out 0.6s;
}

.btn-outline-Received img {
  content: url('../../public/images/Received.png');
  width: 18px !important;
  height: 18px !important;
}

.btn-outline-Received.active img,
.btn-outline-Received:hover img {
  content: url('../../public/images/activeReceived.png');
}

.btn-outline-Received.active,
.btn-outline-Received:hover {
  color: #fff;
  background-color: #64b769;
  border-color: #64b769;
  transform: scale(0.9);
}

.btn-outline-onHold {
  color: #c31b4b;
  background-color: #ffdce1;
  background-image: none;
  border-color: #c31b4b;
  transition: transform ease-in-out 0.6s;
}

.btn-outline-onHold img {
  content: url('../../public/images/onHold.png');
  width: 18px !important;
  height: 18px !important;
}

.btn-outline-onHold.active img,
.btn-outline-onHold:hover img {
  content: url('../../public/images/activeOnHold.png');
}

.btn-outline-onHold.active,
.btn-outline-onHold:hover {
  color: #fff;
  background-color: #c31b4b;
  border-color: #c31b4b;
  transform: scale(0.9);
}

.btn-outline-customerHold {
  color: #9fa950;
  background-color: #eff3d2;
  background-image: none;
  border-color: #9fa950;
  transition: transform ease-in-out 0.6s;
}

.btn-outline-customerHold img {
  content: url('../../public/images/customerHold.png');
  width: 18px !important;
  height: 18px !important;
}

.btn-outline-customerHold.active img,
.btn-outline-customerHold:hover img {
  content: url('../../public/images/ActivecustomerHold.png');
}

.btn-outline-customerHold.active,
.btn-outline-customerHold:hover {
  color: #fff;
  background-color: #9fa950;
  border-color: #9fa950;
  transform: scale(0.9);
}

.btn-outline-ship {
  color: #f39229;
  background-color: #ffedda;
  background-image: none;
  border-color: #f39229;
  transition: transform ease-in-out 0.6s;
}

.btn-outline-ship img {
  content: url('../../public/images/shipping.png');
  width: 18px !important;
  height: 18px !important;
}

.btn-outline-ship.active img,
.btn-outline-ship:hover img {
  content: url('../../public/images/activeShiping.png');
}

.btn-outline-ship.active,
.btn-outline-ship:hover {
  color: #fff;
  background-color: #f39229;
  border-color: #f39229;
  transform: scale(0.9);
}

.btn-outline-refused {
  color: #5bc0de;
  background-color: #daf7ff;
  background-image: none;
  border-color: #5bc0de;
  transition: transform ease-in-out 0.6s;
}

.btn-outline-refused img {
  content: url('../../public/images/refused.png');
  width: 18px !important;
  height: 18px !important;
}

.btn-outline-refused.active img,
.btn-outline-refused:hover img {
  content: url('../../public/images/activeRefused.png');
}

.btn-outline-refused.active,
.btn-outline-refused:hover {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de;
  transform: scale(0.9);
}

.btn-outline-missing {
  color: #337ab7;
  background-color: #cbe7ff;
  background-image: none;
  border-color: #337ab7;
  transition: transform ease-in-out 0.6s;
}

.btn-outline-missing img {
  content: url('../../public/images/missed.png');
  width: 18px !important;
  height: 18px !important;
}

.btn-outline-missing.active img,
.btn-outline-missing:hover img {
  content: url('../../public/images/activeMissed.png');
}

.btn-outline-missing.active,
.btn-outline-missing:hover {
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
  transform: scale(0.9);
}

.btn-outline-Packed {
  color: #6b2504;
  background-color: #ffe6da;
  background-image: none;
  border-color: #6b2504;
  transition: transform ease-in-out 0.6s;
}

.btn-outline-Packed img {
  content: url('../../public/images/packed.png');
  width: 18px !important;
  height: 18px !important;
}

.btn-outline-Packed.active img,
.btn-outline-Packed:hover img {
  content: url('../../public/images/activePacked.png');
}

.btn-outline-Packed.active,
.btn-outline-Packed:hover {
  color: #fff;
  background-color: #6b2504;
  border-color: #6b2504;
  transform: scale(0.9);
}

.btn-outline-PartialReceived {
  color: #006400;
  background-color: #e4ffe4;
  background-image: none;
  border-color: #006400;
  transition: transform ease-in-out 0.6s;
}

.btn-outline-PartialReceived img {
  content: url('../../public/images/partial_received.png');
  width: 18px !important;
  height: 18px !important;
}

.btn-outline-PartialReceived.active img,
.btn-outline-PartialReceived:hover img {
  content: url('../../public/images/activepartial_received.png');
}

.btn-outline-PartialReceived.active,
.btn-outline-PartialReceived:hover {
  color: #fff;
  background-color: #006400;
  border-color: #006400;
  transform: scale(0.9);
}

.well {
  border-radius: 5px;
  background-color: #ededed;
  padding: 20px;
  margin: 20px;
}

.chkAll {
  margin: 5px 10px;
  line-height: 1;
}

.trackImg {
  height: 60px;
  width: 60px;
}

.progressBarStyle {
  margin-left: 11%;
  margin-top: 90px !important;
}

.progress {
  background-color: #c0c0c0;
  height: 8px;
}

.progressImg {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 19%; */
  width: 15%;
}

.form-control {
  background-color: #f9f9f9;
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 90%;
  }
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    max-width: 90%;
  }
}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.notes-Danger {
  font-size: 14px;
  color: #ff1b1b;
  font-family: "Poppins";
  font-weight: 500;
}

.m-auto {
  margin: auto;
}

.receiverAdd {
  font-size: 14px;
  color: #000000;
  font-family: "Poppins";
  font-weight: 400;
}

.modal-title,
.bootbox-body,
.bootbox-cancel,
.bootbox-accept {
  font-family: "Poppins";
}

.printDeleteBox {
  float: right;
  position: relative;
  bottom: 40px;
}

.isNew {
  min-height: 100px;
  margin-bottom: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  border: 1px solid black;
  background-color: #ffffe0;
}

.isReceived {
  min-height: 100px;
  margin-bottom: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  border: 1px solid #65b76a;
  background-color: #96ec9b;
}

.isPending {
  min-height: 100px;
  margin-bottom: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  border: 1px solid #ff2720;
  background-color: #fec8c5;
}

.isMissing {
  min-height: 100px;
  margin-bottom: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  border: 1px solid #337ab7;
  background-color: #bcdefa;
}

.shipStaus {
  height: 20px;
  border-radius: 3px;
  background-color: #406db6;
  color: white;
  font-size: 12px;
  font-family: "Poppins";
  font-weight: bold;
  padding: 3px 8px;
}

.openStatus {
  border-radius: 10px 0 0 10px;
}

.msgCount {
  border-radius: 0 10px 10px 0;
}

.unreadTicket {
  background-color: #eef4ff;
}

.HW35 {
  height: 35px;
  width: 35px;
}

.chatTime {
  font-size: 14px;
  color: #7F7F7F;
  font-family: "Poppins";
  font-weight: 400;
}

.chatBox {
  border-radius: 8px;
  padding: 15px;
  color: white;
  font-family: "Poppins";
  font-size: 14px;
}

.msgMarginRight {
  margin-right: 10%;
  border-radius: 0 20px 20px 20px;
  max-width: max-content;
  word-break: break-all
}

.msgMarginLeft {
  margin-left: 10%;
  float: right;
  border-radius: 20px 0 20px 20px;
  max-width: max-content;
  word-break: break-all
}

.msg-right {
  display: flow-root;
}

.msg-image-div {
  display: flex;
  flex-direction: column;
}

.msg-image {
  width: 200px;
}

.mainChatBox {
  height: 450px;
  background: white;
  overflow: scroll;
  margin-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
}

/* ::-webkit-scrollbar {
  display: none;
} */

/* .scroll-bar::-webkit-scrollbar{
  display: block !important;
} */

.tableHeaderLabel {
  font-size: 16px !important;
  color: #547ab8 !important;
  font-family: "Poppins";
  font-weight: 600 !important;
  background-color: #ebf3ff;
}



/* DropDown Styles */
.textDecorationNone {
  text-decoration: none !important;
}


.dropdown-item>img.generalSettings {
  content: url('../../public/images/gear.png');
  width: 15px !important;
  height: 15px !important;
  margin-right: 8px
}

.dropdown-item:hover>img.generalSettings {
  /* content: url('../../public/images/gearWhite.png'); */
  content: url('../../public/images/gear.png');
  width: 15px !important;
  height: 15px !important;
  margin-right: 8px
}

.genSettings {
  height: 25px;
  width: 25px;
  border-radius: 13px;
  background-color: #ffffff;
  border: 1px solid #000000;
}

.genSettings {
  height: 25px;
  width: 25px;
  border-radius: 13px;
  background-color: #03409b;
}

.nav-item-hold .arrow {
  position: absolute;
  width: 0;
  height: 0;
  right: 0;
  bottom: 28%;
  border-color: transparent #FFFFFF transparent transparent;
  border-style: solid;
  border-width: 20px;
  display: none;
}

.nav-item-hold:hover .arrow {
  display: block;
}

.nav-item:hover .arrow,
.nav-item:hover .store-menu {
  display: inline-block;
}

.store-menu {
  padding: 0;
  list-style: none;
  background: white;
  border-radius: 6px;
  position: absolute;
  right: -150px;
  top: -15px;
  width: 150px;
  box-shadow: 10px 0 10px 0 #ccc;
  display: none;
}

.store-menu li {
  padding: 10px;
  /* border-bottom: 1px solid #e5e5e5; */
}

.store-list .linav-text {
  font-size: 14px !important;
  color: #000000 !important;
  font-family: "Poppins";
  font-weight: 400 !important;
  text-decoration: none !important;
  display: inline-block;
}

.store-list .linav-text:hover {
  color: #000000 !important;
  text-decoration: none !important;
}

/* hover and animation effects */

.btn.BoxImg {
  transition: all ease-in-out 0.6s;
}

.btn.BoxImg:hover {
  transform: scale(1.25);
}

.btn.BoxImg:hover img {
  /* animation: filp 4s linear infinite ; */
  animation: filp 4s linear;
}

@-moz-keyframes filp {
  100% {
    -moz-transform: rotateY(360deg);
  }
}

@-webkit-keyframes filp {
  100% {
    -webkit-transform: rotateY(360deg);
  }
}

@keyframes filp {
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* header menu  */

.myMenu {
  padding: 10px 20px;
}

.item-list {
  display: block;
  width: 100%;
  clear: both;
  white-space: nowrap;
  padding: 4px 0;
  border-bottom: 1px solid #e5e5e5;
  text-align: inherit;
  text-decoration: none;
  font-family: "Poppins";
}

.item-list span {
  display: inline-block;
  text-align: inherit;
  color: #000000;
  font-size: 12px;
}

.imgCircle {
  height: 22px;
  width: 22px;
  border-radius: 11px;
  border: 1px solid #b2b2b2;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-right: 6px;
  display: inline-flex !important;
  background-color: white;
}

.item-list:hover .imgCircle {
  background-color: #03409b;
  border-color: #03409b;
}

.imgCircle img {
  transition: opacity 5s ease-in-out;
}

.item-list:hover .imgCircle img {
  filter: invert(100%);
}

.dropdown-toggle {
  cursor: pointer;
}

.rounded-circle:hover {
  box-shadow: 0 0px 10px 1px hsla(0, 0%, 100%, 0.514);
  /* box-shadow: 0 0px 10px 1px rgba(0,0,0,0.8); */
}

/* *************** customer web ************** */
.cust-item-list {
  display: block;
  width: 100%;
  white-space: nowrap;
  text-decoration: none;
  font-family: "Poppins";
}

.cust-item-list:hover {
  display: block;
  width: 100%;
  white-space: nowrap;
  text-decoration: none;
  font-family: "Poppins";
}

.cust-item-list span {
  display: inline-block;
  text-align: inherit;
  color: #000000;
  font-size: 14px;
}

.Link {
  padding: 5px;
  transition: opacity 5s ease-in-out;
  margin: 2px 0px;
}

.Link:hover {
  background-color: #F8F8F8;
  border-radius: 5px;
}

.cust-dropdown-menu {
  position: absolute;
  top: 56px !important;
  left: -140px !important;
  z-index: 1000;
  transform: none !important;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0 0;
  margin: .125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0px solid rgba(0, 0, 0, .15);
  border-radius: .25rem;
  box-shadow: #cccccc 0px 3px 9px;
  border-top: 4px solid #DC3535
}

.pointer {
  cursor: pointer;
}

.notif-dropdown-menu {
  position: absolute;
  top: 33px !important;
  left: -105px !important;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0 0;
  margin: .125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0px solid rgba(0, 0, 0, .15);
  border-radius: .25rem;
  box-shadow: #cccccc 0px 3px 9px;
  border-top: 4px solid #DC3535
}

.headNotifProfile {
  height: 30px;
  width: 30px;
  background-color: #0B429A;
  border-radius: 20px;
  padding: 3px 11px;
}

.msgTopTriangle {
  content: " ";
  position: absolute;
  right: 13px;
  top: -17px;
  border-top: none;
  border-right: 12px solid transparent;
  border-left: 12px solid transparent;
  border-bottom: 15px solid #DC3535;
}

input[type='file'] {
  color: transparent;
  /* Hides your "No File Selected" */
}

.mrgnTop40 {
  margin-top: 40px;
}

.mrgnTop110 {
  margin-top: 110px;
}

.mrgnTop90 {
  margin-top: 90px;
}

/* *************** customer tab ************** */
.mainDiv {
  height: 70px;
  /* background-color: #0B429A; */
  border-radius: 5px;
}

.mainCircle {
  height: 80px;
  width: 80px;
  background-color: #0B429A;
  border-radius: 40px;
  padding: 7px;
}

.subCircle {
  height: 65px;
  width: 65px;
  background-color: #fff;
  border-radius: 32.5px;
  padding: 2px;
}

.redCircle {
  height: 61px;
  width: 61px;
  background-color: #DC3535;
  border-radius: 31.5px;
}

.tabScroll {
  height: 100%;
}

.webCenter {
  text-align: -webkit-center;
}

.mTopMinus15 {
  margin-top: -15px !important
}

.zIndex {
  z-index: 9999;
}

.tabImg {
  width: 30px;
  /* margin-top: 16px; */
}

.imgInvers {
  filter: invert(0.7);
}

.tabText {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  /* margin-top: 25px; */
}

.unset {
  position: unset;
}

/* *************** new order ************** */

.stepClearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}

.wrapper {
  text-align: -webkit-center;
}

.stepDiv {
  margin: auto;
  width: 180px;
}

.StepNav {
  margin-top: 40px;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  background-color: #0B429A;
}

.m20 {
  margin: 20px;
}

.stepA,
a:active {
  color: #333;
  text-decoration: none;
}

.stepA:hover {
  color: #999;
}

/* Breadcrups CSS */

.arrow-steps .step {
  font-size: 14px;
  text-align: center;
  color: #000;
  cursor: default;
  margin: 0 3px;
  padding: 6px 10px 6px 30px;
  min-width: 135px;
  float: left;
  position: relative;
  background-color: #d1d1d1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.2s ease;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
  content: " ";
  position: absolute;
  top: 0;
  right: -17px;
  width: 0;
  height: 0;
  border-top: 19px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 17px solid #d1d1d1;
  z-index: 2;
  transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
  right: auto;
  left: 0;
  border-left: 17px solid #ffffff;
  z-index: 0;
}

.arrow-steps .step:first-child:before {
  border: none;
}

.arrow-steps .step:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.arrow-steps .step span {
  position: relative;
}

.arrow-steps .step span:before {
  opacity: 0;
  content: "✔";
  position: absolute;
  top: -2px;
  left: -20px;
}

.arrow-steps .step.done span:before {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease 0.5s;
  -moz-transition: opacity 0.3s ease 0.5s;
  -ms-transition: opacity 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s;
}

.arrow-steps .step.current {
  color: #fff;
  background-color: #0B429A;
}

.arrow-steps .step.current:after {
  border-left: 17px solid #0B429A;
}

.width20 {
  width: 20%
}

.ordPrfBox {
  height: 50px;
  width: 50px;
  border: 1px solid #efefef;
  border-radius: 5px;
}

/* ********** add item comp ********** */
.flex {
  display: flex;
}

.input {
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid #e5e5e5;
}

.addItemMain {
  border-top: 4px solid #DC3535;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: rgba(200, 200, 200, 1) 0 0 10px;
}

.myAccInfoMain {
  border-top: 4px solid rgb(11, 66, 154);
  border-radius: 5px;
  background-color: #fff;
  box-shadow: rgba(200, 200, 200, 1) 0 0 10px;
}

.addItemScroll {
  max-height: 463px;
  overflow-y: scroll;
  position: relative;

}

.boxShadowDiv {
  padding: 20px;
  background: white;
  box-shadow: rgba(200, 200, 200, 1) 0 0 10px;
  border-radius: 5px;
  margin: 0 16px 14px;
}

.box-invoice {
  margin: 0;
}

.headText {
  font-size: 24px;
  margin-left: 15px;
  color: #7f7f7f;
}

.labelFs14 {
  font-size: 14px !important;
  color: #9f9f9f
}

.labelFs12 {
  font-size: 12px !important;
  color: #9f9f9f
}

.labelFs10 {
  font-size: 10px !important;
  color: #9f9f9f
}

.inputFs14 {
  font-size: 14px !important;
  color: #404040
}

.inputFs12 {
  font-size: 12px !important;
  color: #404040
}

.addItemBtn {
  background: #DC3535;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  padding: 5px 10px;
}

.uploadBtn {
  background: #DC3535;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  padding: 5px 10px;
  width: 113px;
}

.mt40 {
  margin-top: 40px;
}

.rotate180 {
  transform: rotate(180deg);
}

.mAuto {
  margin: auto;
}

.themeBlack {
  color: #404040
}

.themeRed {
  color: #DC3535;
}

.themeBlue {
  color: #0B429A;
}

.themeBlueLight {
  color: #C7DDFF;
}

.themeGreen {
  color: #64B769;
}

.radio {
  width: 18px;
  height: 18px;
  border: 2px solid #0B429A;
  border-radius: 9px;
}

.radioDisable {
  margin-top: -2px;
  margin-left: -2px;
  width: 18px;
  height: 18px;
  border: 2px solid #0B429A;
  border-radius: 9px;
}

.radioCircle {
  background-color: #0B429A;
  height: 8px;
  width: 8px;
  border-radius: 4px;
  margin: 3px;
}

.addressBrdr {
  border-bottom: 1px solid #e5e5e5;
}

.boxShadowAdd {
  /* padding: 20px; */
  background: white;
  box-shadow: rgba(200, 200, 200, 1) 0 0 10px;
  border-radius: 5px;
}

.editColor {
  filter: contrast(0.5);
}

.binColor {
  filter: brightness(0.5);
}

.editFont {
  font-size: 10px;
  color: #9f9f9f
}

.menuPopup {
  width: 75px;
  padding: 5px 10px;
  background: white;
  box-shadow: rgba(200, 200, 200, 1) 0 0 10px;
  border-radius: 5px;
  position: absolute;
  right: 30px;
  margin-left: -60px;
}

.bgWhite {
  background-color: white;
}

.white {
  color: white;
}

.uploadDiv {
  border: 1px dashed #cccccc;
  border-radius: 5px;
  padding: 100px 50px;
}

.sortColumn {
  position: relative;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.sorting::after {
  top: 10px;
  right: 10px;
  opacity: .2;
  content: "\f0dc";
  position: absolute;
  font: normal normal normal 14px/1 FontAwesome;
}

.sorting_asc::after {
  top: 10px;
  right: 10px;
  opacity: .6;
  content: "\f160";
  position: absolute;
  font: normal normal normal 14px/1 FontAwesome;
}

.sorting_desc::after {
  top: 10px;
  right: 10px;
  opacity: .6;
  content: "\f161";
  position: absolute;
  font: normal normal normal 14px/1 FontAwesome;
}

.capital {
  text-transform: uppercase;
}

/* ********** my invoice ********** */
.invMain {
  border-top: 4px solid #DC3535;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: rgba(200, 200, 200, 1) 0 0 10px;
}

.tabBtn {
  height: 30px;
  width: 70px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
  font-size: 14px;
  padding: 5px;
}

.bottomBrdrLight {
  border-bottom: 1px solid #efefef;
}

.bottomBrdr {
  border-bottom: 1px solid #cccccc;
}

.boxShadowInv {
  padding: 10px 10px;
  background: white;
  box-shadow: rgba(200, 200, 200, 1) 0 0 10px;
  border-radius: 5px;
  border-left: 4px solid;
}

.boxShadowShip {
  padding: 10px 10px;
  background: white;
  box-shadow: rgba(200, 200, 200, 1) 0 0 10px;
  border-radius: 5px;
}

/* ********** my order ********** */
.orderTabBtn {
  /* height: 30px; */
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
  font-size: 18px;
  padding: 13px;
  box-shadow: 0 1px 4px 1px rgb(0 0 0 / 50%);
}

.width14 {
  width: 14.28%
}

.dFlex {
  display: flex
}

/* ********* my shimpemt ********** */
.shipWidth {
  width: 20%;
}

.menuCircle {
  width: 40px !important;
  height: 40px !important;
  background: #C7DDFF;
  border-radius: 20px;
  /* padding: 8px 12px; */
}

.menuImgMrgn {
  margin: 12px;
}

.notifDiv {
  position: absolute;
  right: -2px;
  top: -5px;
}

.notifSubDiv {
  background-color: red;
  padding: 0px 7px;
  border-radius: 12px;
}

/* ********* rates ********** */
.boxShadowRate {
  padding: 20PX;
  background: white;
  box-shadow: rgba(200, 200, 200, 1) 0 0 10px;
  border-radius: 5px;
}

/* ********* Contact Us Chat********** */
.attachmentIcon {
  width: 45px;
  height: 40px;
  background-color: transparent;
  margin-top: 6px;
  border: 0px
}

.lastTicketMsg {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chatProfileImg {
  max-width: 100%;
}

.inbox_people-main {
  float: left;
  /* border:1px solid #efefef; */
  border-radius: 5px;
}

.inbox_people {
  float: left;
  border: 1px solid #ebebeb;
  border-radius: 5px;
}

.inbox_msg {
  /* border: 1px solid #efefef; */
  clear: both;
  overflow: hidden;
}

.top_spac {
  margin: 20px 0 0;
}


.recent_heading {
  float: left;
  width: 40%;
}

.srch_bar {
  display: inline-block;
  text-align: right;
  width: 60%;
}

.headind_srch {
  padding: 10px 29px 10px 20px;
  overflow: hidden;
  border-bottom: 1px solid #c4c4c4;
}

.recent_heading h4 {
  color: #05728f;
  font-size: 21px;
  margin: auto;
}

.srch_bar input {
  border: 1px solid #cdcdcd;
  border-width: 0 0 1px 0;
  width: 80%;
  padding: 2px 0 4px 6px;
  background: none;
}

.srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}

.srch_bar .input-group-addon {
  margin: 0 0 0 -27px;
}

.chat_ib h5 {
  font-size: 14px;
  color: #404040;
  margin: 0 0 2px 0;
}

.chat_ib h5 span {
  font-size: 10px;
  color: #9f9f9f;
  float: right;
}

.chat_ib p {
  font-size: 12px;
  color: #9f9f9f;
  margin: auto
}

.chat_img {
  float: left;
  width: 11%;
}

.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 100%;
}

.chat_people {
  overflow: hidden;
  clear: both;
}

.chat_list {
  border-bottom: 1px solid #ebebeb;
  margin: 0;
  padding: 18px 16px 10px;
}

.inbox_chat {
  height: 550px;
  overflow-y: scroll;
}

.active_chat {
  background: #f1f1f1;
}

.incoming_msg_img {
  display: inline-block;
  width: 6%;
}

.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
}

.received_withd_msg {
  background: #eaeaea none repeat scroll 0 0;
  border-top-left-radius: 0px !important;
  border-radius: 10px;
  color: #404040;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
  overflow-wrap: break-word;
}

.time_date {
  color: #9f9f9f;
  display: block;
  font-size: 12px;
  margin: 2px 0 8px 0;
}

.received_withd_msg {
  width: 80%;
  max-width: max-content;
}

.mesgs {
  float: left;
  /* padding: 30px 15px 0 25px; */
  /* width: 60%; */
  border: 1px solid #efefef;
  border-radius: 5px;
}

.sent_msg-data {
  background: #BED7FF none repeat scroll 0 0;
  border-top-right-radius: 0px !important;
  border-radius: 10px;
  font-size: 14px;
  margin: 0;
  color: #404040;
  padding: 5px 10px 5px 12px;
  width: 100%;
  overflow-wrap: break-word;

}

.outgoing_msg {
  overflow: hidden;
  margin: 26px 0 26px;
}

.sent_msg {
  float: right;
  width: 80%;
  max-width: max-content;

}

.input_msg_write p {
  margin: 16px;
}

.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  /* width: 85%; */
  padding-right: 50px !important;
  width: -webkit-fill-available;
  margin-top: 3px;
}

.type_msg {
  border-top: 1px solid #efefef;
  position: relative;
}

.msg_send_btn {
  background: #0B429A none repeat scroll 0 0;
  border: medium none;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  font-size: 13px;
  height: 29px;
  /* position: absolute;
  right: 0;
  top: 0px; */
  width: 110px;
}

.messaging {
  padding: 0 0 50px 0;
}

.msg_history {
  height: 460px;
  overflow-y: auto;
}

.msgBoxTitleDiv {
  /* height: 70px; */
  overflow: hidden;
  border-bottom: 1px solid #ebebeb;
  display: inline-flex;
}

.ticketBtn {
  height: 30px;
  border-radius: 5px;
  background-color: #0B429A;
  border: 0px;
  color: #fff;
  width: 100px;
  font-size: 14px;
}

.ticketcloseBtn {
  border-radius: 5px;
  background-color: #DC3535;
  border: 0px;
  color: #fff;
  font-size: 12px;
  padding: 3px 5px;
  margin-top: 10px;
}

.ticketOpenBtn {
  border-radius: 5px;
  background-color: #64B769;
  border: 0px;
  color: #fff;
  font-size: 12px;
  padding: 3px 5px;
  margin-top: 10px;
}

.contactWidth {
  width: -webkit-fill-available;
}

.drop-mymenu {
  left: -117px !important;
}

/* *************** tracking style ************** */
.vertical-timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 2px !important;
  background: #e1e1e1 !important;
}

.vertical-timeline--two-columns .vertical-timeline-element-content {
  margin-left: 0;
  padding: 10px !important;
  width: 44%;
}

.vertical-timeline-element-content p {
  margin: 0 !important;
}

.vertical-timeline-element-content {
  position: relative;
  margin-left: 60px;
  background: #fff;
  border-radius: .25em;
  padding: 1em;
  box-shadow: 0 0 15px #ccc !important;
  margin-top: -25px !important;

}

.vertical-timeline-element-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 3px 0 transparent !important;
}

.vertical-timeline--two-columns .vertical-timeline-element-icon {
  width: 30px !important;
  height: 30px !important;
  left: 50%;
  margin-left: -17px !important;
}

/* **************** about us web **************** */
.aboutImg {
  height: 400px;
  width: 100%;
  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
}

.homeImg {
  height: 600px;
  width: 100%;
  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
}

.staticHeader {
  width: 100%;
  /* height: 80px; */
  -webkit-box-shadow: 0 1px 15px rgba(0, 0, 0, .04), 0 1px 6px rgba(0, 0, 0, .04);
  box-shadow: 0 1px 15px rgba(0, 0, 0, .04), 0 1px 6px rgba(0, 0, 0, .04);
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  /* justify-content: space-between; */
  /* background: url('../../public/images/BgBlue.png') center no-repeat #09386e; */
  z-index: 100;
  background-color: #fff;
  border-radius: 5px;
}

.aboutSignIn {
  height: 30px;
  width: 100px;
  font-size: 13px;
  color: #fff;
  border-radius: 20px;
  background-color: #1C89E3;
  border: 0px;
}

.aboutRegister {
  height: 30px;
  width: 100px;
  font-size: 13px;
  color: #fff;
  border-radius: 20px;
  background-color: #168F46;
  border: 0px;
}

.bigTextEm {
  font-size: 4vw;
  color: #fff;
}

.redDivider {
  height: 2px;
  width: 130px;
  background: #DC3535;
  border-radius: 5px;
}

.redDividerSmall {
  height: 2px;
  width: 50px;
  background: #DC3535;
  border-radius: 5px;
}

.redDividerToSmall {
  height: 2px;
  width: 30px;
  background: #DC3535;
  border-radius: 5px;
}

.paddPer {
  padding: 0% 25%;
}

.aboutUs-img-view {
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 15px;
  /* padding-bottom: 15px;  */
  border: 2px solid #DC3535;
  height: 350px;
}

.aboutUs-img-mrgn1 {
  margin-top: 30px;
  margin-left: 30px;
}

.aboutUs-img-mrgn2 {
  margin-top: 30px;
  margin-left: -30px;
}

@media (max-width:575px) {
  .aboutUs-img-mrgn1 {
    margin-top: 15px;
    margin-left: 15px;
  }

  .aboutUs-img-mrgn2 {
    margin-top: 15px;
    margin-left: -15px;
  }
}

@media (max-width:350px) {
  .signup-logo {
    width: 200px;
    height: 75px;
  }
}

/* **************** contact us web **************** */
.webContactBox {
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  height: 135px;
  padding: 30px 20px;
}

.webContactBox:hover {
  border: 1px solid #DC3535;
  border-radius: 10px;
  height: 135px;
  padding: 30px 20px;
  box-shadow: 0px 0 10px 0 #ccc;
  transition: box-shadow ease-in-out 0.1s, transform ease-in-out 0.1s;
}

.webInput {
  border: 0;
  outline: 0;
  background-color: #e5e5e5;
  padding: 13px !important;
  border-radius: 5px;
}

.fs22 {
  font-size: 22px !important;
}

.fs25 {
  font-size: 25px !important;
}

.sendBtn {
  height: 40px;
  padding: 0px 20px;
  border-radius: 5px;
  background-color: #0B429A;
  border: 0px;
  color: #fff;
}

.wrd-break {
  word-break: break-all;
}

/* **************** faqs web**************** */
.Collapsible-head {
  border-radius: 5px;
  border: 1px solid #ebebeb;
  padding-top: 13px;
  margin-bottom: 13px;
  color: #0B429A
}

.Collapsible {
  border-radius: 5px;
  border: 1px solid #ebebeb;
  padding: 13px 15px;
  margin-bottom: 13px;
  color: #0B429A
}

.Collapsible__contentInner {
  /* height: 50px; */
  border-top: 1px solid #ebebeb;
  padding-top: 10px;
  margin-top: 10px;
}

/* **************** home web **************** */
.HomeCircleRed {
  height: 73px;
  width: 73px;
  border: 1px solid #0B429A;
  border-radius: 50px;
  padding: 3px;
  /* margin-left: 40px; */
}

.HomeCircleBlue {
  height: 73px;
  width: 73px;
  border: 1px solid #DC3535;
  border-radius: 50px;
  padding: 3px;
  /* margin-left: 40px; */
}

.homeSubCircle {
  height: 65px;
  width: 65px;
  background-color: #fff;
  border-radius: 32.5px;
  padding: 2px;
}

.HomeRedCircle {
  height: 61px;
  width: 61px;
  background-color: #DC3535;
  border-radius: 31.5px;
}

.HomeBlueCircle {
  height: 61px;
  width: 61px;
  background-color: #0B429A;
  border-radius: 31.5px;
}

.arrowDiv {
  height: 70px
}

.flowRoot {
  display: flow-root;
}

.homeImgWidth {
  width: 100%
}

.bottomArrowWidth {
  width: 150px
}

.homeFlex {
  display: flex;
}

.homeMrgn {
  margin-right: 67px;
}

.mLeft40 {
  margin-left: 0px;
}

.mLeft40Blue {
  margin-left: 40px;
}

.imgDisplay {
  display: none;
}

.homeDisplay {
  display: block
}

.homeDisplay2 {
  display: none
}

/* **************** prohibited web **************** */
.prbBtnDiv {
  /* width: 200px; */
  background-color: #0B429A;
  padding: 10px 20px;
  border-radius: 20px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  display: inline-block;
}

/* **************** web login **************** */
.webLoginImg {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
}

.centerDiv {
  position: absolute;
  top: 25%;
  width: 90%;
  left: 5%
}

.webLoginDiv {
  background-color: #fff;
  border-radius: 5px;
}

.webTabBtn {
  padding: 20px 0px;
  background-color: #0B429A;
}

.webTabBtnWhite {
  padding: 20px 0px;
  background-color: #fff;
}

.borderRight {
  border-right: 1px solid #cccccc;
}

.webLoginInput {
  border: 0;
  outline: 0;
  border: 1px solid #e5e5e5;
  padding: 5px 13px !important;
  border-radius: 5px;
}

.webLoginBtn {
  background: #0B429A;
  padding: 7px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
}

.webLoginFlex {
  display: flex;
}

/* **************** footer **************** */
.page-footer {
  background-color: #404040;
}

.footerTitle {
  font-size: 16px;
  color: #fff;
}

.footIconDiv {
  width: 26px;
  height: 26px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 1px 8px;
}

/* **************** customer notification **************** */
.notifProfile {
  height: 40px;
  width: 40px;
  background-color: #0B429A;
  border-radius: 20px;
  padding: 8px;
}

.alink:hover {
  color: #fff;
  transition: 1s;
}

/* ******************************** */

/* **************** Email Template **************** */
/* **************** forgot password **************** */
.emailMainDiv {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  background-color: #0B429A;
}

.emailDiv {
  background-color: #fff;
  border-radius: 5px;
  border-bottom: 4px solid #DC3535;
}

.emailSubDiv {
  background-color: #fff;
  border-radius: 5px;
  height: 5px;
}

.emailPassBtn {
  height: 40px;
  border-radius: 5px;
  background-color: #DC3535;
  border: 0px;
  color: #fff;
  font-size: 16px;
  padding: 0px 20px;
  margin: 10px;
}

.emailCopyInput {
  border: 0;
  outline: 0;
  width: 80px
}

.order-item-close {
  position: absolute;
  top: 0;
  padding: 10px;
}

.image-preview-img-wrap {
  max-width: 500px;
  width: 100%;
  min-height: 500px;
  margin: 0 auto;
}

.image-preview-img-wrap img {
  width: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  height: 100%;
  object-fit: contain;
}

/* ******************************** */

@media (max-width: 1199px) {
  .width14 {
    width: 26%;
  }

  .homeMrgn {
    margin-right: 55px;
  }
}

@media (max-width: 878px) {
  .width14 {
    width: 33%;
  }
}

@media (max-width: 767px) {
  .width14 {
    width: 100% !important;
  }

  .shipWidth {
    width: 50%;
  }

  .menuWidth {
    width: 100%
  }

  .dFlex {
    display: inline-block
  }

  .mrgnTop50 {
    margin-top: 50px;
  }

  .width20 {
    width: 100%;
  }

  .homeFlex {
    display: table;
  }

  .displayNone {
    display: none;
  }

  .mLeft40 {
    margin-left: 0px;
  }

  .mLeft40Blue {
    margin-left: 0px;
  }

  .homeCenter {
    text-align: -webkit-center;
  }

  .imgDisplay {
    display: block;
  }

  .webLoginFlex {
    display: block;
  }

  .homeDisplay {
    display: none
  }

  .homeDisplay2 {
    display: block
  }

  .chat_img {
    width: 100%
  }

  .chat_ib {
    display: none
  }

  .chat_list {
    border-bottom: 1px solid #ebebeb;
    margin: 0;
    padding: 10px 10px 10px;
  }

  .chatLeftWidth {
    width: 20%
  }

  .chatRightWidth {
    width: 80%
  }
}

@media (max-width: 600px) {
  .shipWidth {
    width: 100%;
  }

  .mainCircle {
    height: 48px;
    width: 48px;
    background-color: #0B429A;
    border-radius: 40px;
    padding: 7px;
  }

  .subCircle {
    height: 34px;
    width: 34px;
    background-color: #fff;
    border-radius: 32.5px;
    padding: 2px;
  }

  .redCircle {
    height: 30px;
    width: 30px;
    background-color: #DC3535;
    border-radius: 31.5px;
  }

  .tabImg {
    width: 12px;
    margin-top: 5px;
  }
}

@media (max-width: 678px) {
  .mainDiv {
    height: 50px;
    /* background-color: #0B429A; */
    border-radius: 5px;
  }

  .mainCircle {
    height: 60px;
    width: 60px;
    background-color: #0B429A;
    border-radius: 40px;
    padding: 7px;
  }

  .subCircle {
    height: 46px;
    width: 46px;
    background-color: #fff;
    border-radius: 32.5px;
    padding: 2px;
  }

  .redCircle {
    height: 42px;
    width: 42px;
    background-color: #DC3535;
    border-radius: 31.5px;
  }

  .tabImg {
    width: 20px;
    margin-top: 12px;
  }
}

@media (max-width: 1169px) {
  .vertical-timeline--two-columns .vertical-timeline-element-content {
    margin-left: 60px;
    padding: 10px !important;
    width: 80%;
  }

  .vertical-timeline--two-columns .vertical-timeline-element-icon {
    width: 30px !important;
    height: 30px !important;
    left: 0% !important;
    margin-left: 0px !important;
  }

  .vertical-timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 15px !important;
    height: 100%;
    width: 2px !important;
    background: #e1e1e1 !important;
  }
}

@media (max-width: 991px) {
  .homeMrgn {
    margin-right: 30px;
  }

  .mLeft40 {
    margin-left: 0px;
  }

  /* .mLeft40Blue {
    margin-left: 15px;
  } */
}

.headTitle {
  font-size: 30px;
  font-weight: 900;
}

.boxInnerMargin {
  margin: 0 16px 14px;
}

.m-14 {
  margin: 14px;
}

.right {
  float: right;
}

.modal-open-delete {
  overflow: hidden;

  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}